import React, {
  ClassAttributes,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';

type PageHeadingProps = PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>;

const PageHeading = forwardRef<HTMLHeadingElement, PageHeadingProps>(
  (props, ref) => {
    return (
      <h1 ref={ref} {...props}>
        {props.children}
      </h1>
    );
  },
);

PageHeading.displayName = 'PageHeading';
export default PageHeading;
