import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { HeadingProps } from '../../types';

interface TitleProps {
  heading: HeadingProps;
  headingColor?: string;
  headingClass?: string;
  heading2Class?: string;
}

const Title = (props: TitleProps) => {
  const {
    heading = { heading1: '' },
    headingColor = 'iv-text-grey-85',
    headingClass = 'xl:iv-text-heading-s iv-text-heading-2xs sm:iv-text-heading-4xs xl:iv-m-heading-s iv-m-heading-2xs sm:iv-m-heading-4xs',
    heading2Class,
  } = props;
  const { heading1, heading2 } = heading;

  return (
    <h3
      className={ivclass(
        'iv-relative',
        'iv-z-10',
        'iv-font-extrabold',
        headingClass,
        headingColor,
      )}
      suppressHydrationWarning={true}
    >
      <span className={ivclass('iv-block', heading2Class)}>{heading1}</span>
      {heading2 && <span>{heading2}</span>}
    </h3>
  );
};

export default Title;
