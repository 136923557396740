import React from 'react';
import Details from '@components/hero-section/Details';
import HeroVideo from '@components/hero-section/HeroVideo';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import Title from '@components/hero-section/Title';
import { BrowserProps, HeroSectionType } from '../../types';

interface HeroSectionProps extends BrowserProps {
  heroSection: HeroSectionType;
  onCTAIntersection?: Function;
}

const HeroSection = ({
  browser,
  heroSection,
  onCTAIntersection,
}: HeroSectionProps) => {
  const { video, poster, heading, sideText, buttonText, CTAUrl, subText } =
    heroSection;
  return (
    <React.Fragment>
      <section
        className={ivclass(
          'iv-pt-100 lg:iv-pt-80 md:iv-pt-75 sm:iv-pt-50 iv-px-20',
        )}
      >
        <div className={ivclass('iv-grid', 'iv-grid-cols-12')}>
          <div
            className={ivclass(
              'iv-col-span-12',
              'xl:iv-col-start-2 lg:iv-col-start-2',
              'xl:iv-pl-5 lg:iv-pl-20',
            )}
          >
            <Title heading={heading} />
          </div>
        </div>
      </section>

      <section
        className={ivclass(
          'iv-relative',
          'md:iv-mt-5',
          'xl:iv-pt-75 lg:iv-pt-50 md:iv-pt-50 sm:iv-pt-[33px] iv-px-20 lg:iv-pl-40',
        )}
      >
        <div className={ivclass('iv-grid', 'iv-grid-cols-12')}>
          <div
            className={ivclass(
              'xl:iv-col-start-2 lg:iv-col-start-2',
              'iv-col-span-4 md:iv-col-span-12 sm:iv-col-span-12',
              'xl:iv-pl-3',
              'md:iv-text-center sm:iv-text-center',
            )}
          >
            <Details
              sideText={sideText}
              buttonText={buttonText}
              CTAUrl={CTAUrl}
              subText={subText}
              onCTAIntersection={onCTAIntersection}
            />
          </div>
          <div
            className={ivclass(
              'md:iv-relative sm:iv-relative',
              'iv-col-span-7 md:iv-col-span-12 sm:iv-col-span-12',
              'xl:iv-h-[685px] lg:iv-h-[685px] md:iv-h-[700px] sm:iv-h-[355px]',
            )}
          >
            <HeroVideo browser={browser} video={video} poster={poster} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HeroSection;
