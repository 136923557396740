import React, { useContext, useState, useEffect } from 'react';
import IVButton from '@invideoio/web-shared/components/Button';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { AUTH_TYPE } from '../../constants/homepage';
import { RedirectContext, StateContext } from '../../store/store';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { SideTextType } from '../../types';

import useIntersectionObserver from '@react-hook/intersection-observer';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';
import { BaseAppType } from '@constants/common';

interface DetailsProps {
  sideText: SideTextType;
  buttonText: string;
  CTAUrl: string;
  subText: string;
  onCTAIntersection?: Function;
}

const Details = (props: DetailsProps) => {
  const { sideText, buttonText, CTAUrl, subText, onCTAIntersection } = props;
  const { handleClick } = useContext(RedirectContext);
  const { baseAppType } = useContext(StateContext);
  const onClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'Top',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.Top,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    });
    handleClick(CTAUrl, {
      tab: AUTH_TYPE.SIGNUP,
      ux_source: ClickCtaUxSource.Top,
    });
  };
  const [ctaRef, setCtaRef] = useState<any>(null);
  const [textRef, setTextRef] = useState<any>(null);

  function useIsIntersecting(sectionRef: any, threshold?: number) {
    const { isIntersecting } = useIntersectionObserver(sectionRef, {
      threshold: threshold,
    });
    return isIntersecting;
  }

  const isCtaIntersecting = useIsIntersecting(ctaRef, 1);
  const isTextIntersecting = useIsIntersecting(textRef, 0);

  useEffect(() => {
    if (onCTAIntersection) {
      onCTAIntersection(isTextIntersecting ? true : isCtaIntersecting);
    }
  }, [isCtaIntersecting, isTextIntersecting, onCTAIntersection]);

  return (
    <React.Fragment>
      <div
        ref={setTextRef}
        className={ivclass(
          'iv-inline-block',
          'iv-pb-50 md:iv-pb-50 sm:iv-pb-40',
          'xl:iv-pr-20 lg:iv-pr-25',
          'xl:iv-pt-8 lg:iv-pt-20 iv-pt-6 md:iv-px-[110px]',
          'lg:iv-mb-4 md:iv-mb-3 lg:iv-mr-4 md:iv-px-10',
          'xl:iv-text-heading-3xs lg:iv-text-heading-4xs md:iv-text-heading-4xs sm:iv-text-body-2xl',
          'xl:iv-m-heading-3xs lg:iv-m-heading-4xs md:iv-m-heading-4xs sm:iv-m-body-2xl',
          'iv-font-semibold',
        )}
      >
        <span
          className={ivclass('iv-text-black-85', 'md:iv-inline sm:iv-inline')}
        >
          {sideText.highlightedText}
        </span>
        <span
          className={ivclass('iv-text-black-50', 'md:iv-inline sm:iv-inline')}
        >
          {sideText.plainText1}
        </span>
        <div
          className={ivclass('iv-text-black-50', 'md:iv-inline sm:iv-inline')}
        >
          {sideText.plainText2}
        </div>
      </div>

      <div className="iv-inline-block iv-text-center">
        <IVButton
          ref={setCtaRef}
          className={ivclass(
            'iv-flex',
            'iv-justify-center',
            'iv-items-center',
            'iv-cursor-pointer',
            'iv-h-65 sm:iv-h-52',
            'iv-rounded-full',
            'iv-bg-blue hover:iv-bg-blue-strong iv-transition',
            'iv-px-40 sm:iv-px-25',
            'iv-mb-6 sm:iv-mb-10 md:iv-mx-auto sm:iv-mx-auto',
            'iv-text-grey-0',
            'iv-text-body-3xl sm:iv-text-body-2xl',
            'iv-font-bold',
          )}
          onClick={onClick}
        >
          {buttonText}
        </IVButton>
        <div
          className={ivclass(
            'iv-pt-10 md:iv-pb-0 sm:iv-pt-1 sm:iv-pb-0',
            'md:iv-mx-auto sm:iv-mx-auto',
            'iv-text-grey-100',
            'iv-text-body-m sm:iv-text-body-xs',
            'iv-font-medium',
          )}
        >
          {subText}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Details;
