import IVCard from '@invideoio/web-shared/components/Card';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { CSSProperties } from 'react';

interface BaseNumberCardProps {
  title: JSX.Element;
  number: JSX.Element;
  details: JSX.Element;
  image?: JSX.Element;
  containerClass?: string;
  style?: CSSProperties;
  wrapperClass?: string;
  contentContainerClass?: string;
  headerContainerClass?: string;
}

const BaseNumberCard = (props: BaseNumberCardProps) => {
  const {
    title,
    number,
    image,
    details,
    containerClass,
    style,
    wrapperClass = '',
    contentContainerClass = '',
    headerContainerClass = '',
  } = props;
  return (
    <IVCard className={ivclass('iv-overflow-hidden')}>
      <div
        className={ivclass(
          'iv-rounded-30',
          'iv-relative',
          'iv-overflow-hidden',
          containerClass,
        )}
        style={style}
      >
        <div className={ivclass('iv-h-full', 'iv-relative', wrapperClass)}>
          <div
            className={ivclass(
              'iv-flex',
              'iv-justify-between',
              'xl:iv-px-[26px] xl:iv-pt-[26px] lg:iv-px-[26px] lg:iv-pt-[26px] md:iv-px-25 md:iv-pt-[23px] iv-px-20 iv-pt-[12px]',
              headerContainerClass,
            )}
          >
            <div className={contentContainerClass}>
              {title}

              {details}
            </div>
            {number}
          </div>
          {image}
        </div>
      </div>
    </IVCard>
  );
};

export default BaseNumberCard;
