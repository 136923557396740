import ivclass from '@invideoio/web-shared/Modules/IVClass';
import Title from '@components/features-card/Title';
import Details from './Details';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import { CSSProperties } from 'react';
import React from 'react';
import BaseNumberCard from './BaseNumberCard';
import { ImageResponseType } from '../../types';
import { BrowserType } from '@invideoio/web-shared/constants/common';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { getAppropriateUrl } from '@invideoio/web-shared/utils/common';

interface NumberFeatureCardProps {
  details: {
    highlightedText: string;
    plainText1?: string;
    plainText2?: string;
  };
  heading: { heading1: string };
  number: number;
  image?: ImageResponseType;
  containerClass?: string;
  highlightedTextClass: string;
  numberClass: string;
  style?: CSSProperties;
  wrapperClass?: string;
  detailsClass?: string;
  headingClass?: string;
  detailsWrapperClass?: string;
  titleColor?: string;
  contentContainerClass?: string;
  headerContainerClass?: string;
  browser?: BrowserType;
  platform?: PlatformType;
}

const NumberFeatureCard = (props: NumberFeatureCardProps) => {
  const {
    heading,
    details,
    number,
    image,
    containerClass,
    wrapperClass,
    highlightedTextClass,
    numberClass,
    detailsClass,
    headingClass,
    detailsWrapperClass,
    titleColor,
    style,
    contentContainerClass = '',
    headerContainerClass = '',
    browser = BrowserType.Chrome,
    platform = PlatformType.Desktop,
  } = props;

  const imageURL = getAppropriateUrl(image?.url ?? '', browser, platform);

  return (
    <BaseNumberCard
      title={
        <Title
          heading={heading}
          heading2Class="md:iv-inline"
          headingColor={titleColor}
          headingClass={headingClass}
        />
      }
      number={
        <div className={ivclass('iv-font-extrabold', numberClass)}>
          {number}
        </div>
      }
      details={
        <div className={ivclass(detailsWrapperClass)}>
          <Details
            highlightedText={details.highlightedText}
            plainText1={details.plainText1}
            plainText2={details.plainText2}
            highlightedTextClass={highlightedTextClass}
            detailsClass={detailsClass}
          />
        </div>
      }
      image={
        image && (
          <div className={ivclass('iv-w-full')}>
            <ImageComponent
              elementype="native"
              src={imageURL}
              alt={image.alternativeText}
              loading="lazy"
              className={ivclass('iv-mx-auto')}
              width="100%"
              height="100%"
            />
          </div>
        )
      }
      containerClass={containerClass}
      style={style}
      wrapperClass={wrapperClass}
      contentContainerClass={contentContainerClass}
      headerContainerClass={headerContainerClass}
    />
  );
};

export default NumberFeatureCard;
