import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { TrustedByType } from '../../types';

interface CustomTrustedByProps {
  trustedBy: TrustedByType[];
  sectionHead: string;
}

const CustomTrustedBy = (props: CustomTrustedByProps) => {
  const { trustedBy, sectionHead } = props;

  return (
    <section
      className={ivclass(
        'xl:iv-pt-200 iv-pt-150 sm:iv-pt-100',
        'iv-mb-[-12px] sm:-iv-mb-[-30px]',
      )}
    >
      <div
        className={ivclass(
          'xl:iv-mb-40 lg:iv-mb-20 md:iv-mb-25',
          'iv-text-grey-50',
          'iv-text-center',
          'iv-text-body-xl sm:iv-text-body-xs',
          'iv-m-body-xl sm:iv-m-body-xs',
          'iv-font-semibold',
        )}
      >
        {sectionHead}
      </div>
      <div
        className={ivclass(
          'lg:iv-px-[83px] md:iv-px-[45px] sm:iv-px-10',
          'iv-pt-7 md:iv-pt-6 sm:iv-pt-[33px]',
        )}
      >
        <div
          className={ivclass(
            'iv-flex',
            'iv-justify-around iv-items-center',
            'sm:iv-flex-wrap',
          )}
        >
          {trustedBy.map((e, idx) => {
            return (
              <div
                key={idx}
                className={ivclass(
                  'sm:iv-flex sm:iv-justify-center',
                  'sm:iv-w-1/2',
                  'sm:iv-mb-30',
                  'iv-w-full',
                )}
              >
                <div
                  className={ivclass(
                    'iv-relative',
                    'iv-w-full',
                    'iv-h-[70px] md:iv-h-[40px] sm:iv-h-[40px]',
                  )}
                >
                  <ImageComponent
                    elementype="next"
                    src={e.image.url}
                    alt={''}
                    sizes="100vw"
                    fill
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CustomTrustedBy;
