import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface DetailsProp {
  highlightedText: string;
  plainText1?: string;
  plainText2?: string;
  highlightedTextClass?: string;
  plainTextColor?: string;
  detailsClass?: string;
}

const Details = ({
  highlightedText,
  plainText1,
  plainText2,
  highlightedTextClass,
  detailsClass,
}: DetailsProp) => {
  return (
    <div className={ivclass('iv-font-medium', detailsClass)}>
      <span className={ivclass(highlightedTextClass)}>{highlightedText}</span>{' '}
      {plainText1}
      <span className={ivclass('iv-block')}>{plainText2}</span>
    </div>
  );
};

export default Details;
