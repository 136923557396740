import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import { getPosterURL } from '@invideoio/web-shared/utils/common';
import {
  BrowserProps,
  PosterType,
  VideoType,
  VideoPosterType,
} from '../../types';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';

interface HeroVideoProps extends BrowserProps {
  video: VideoType;
  poster: VideoPosterType;
}

const HeroVideo = (props: HeroVideoProps) => {
  const { browser, video, poster } = props;
  const videPoster: PosterType = poster.desktop || { defaultURL: poster.url };

  const posterURL = getPosterURL(videPoster, browser);

  return (
    <div className={ivclass('iv-hero-video')}>
      {video?.url && (
        <VideoComponent
          autoPlay
          loop
          muted
          width="100%"
          height="100%"
          poster={posterURL}
          loading="preload"
        >
          <source src={video?.url} />
        </VideoComponent>
      )}
      {!video?.url && posterURL && (
        <ImageComponent
          elementype="native"
          src={posterURL}
          alt="hero"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default HeroVideo;
