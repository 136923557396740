import ivclass from '@invideoio/web-shared/Modules/IVClass';
import useTranslation from 'next-translate/useTranslation';

import { HOMEPAGE_BRANDS } from '../../constants/homepage';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';

interface TrustedByProps {
  isMobile: boolean;
  sectionClass?: string;
}

const TrustedBy = (props: TrustedByProps) => {
  const { isMobile, sectionClass } = props;
  const { t } = useTranslation();

  const sectionData = HOMEPAGE_BRANDS;
  const logosOrder = [
    'iv-order-1',
    'iv-order-6',
    'iv-order-5',
    'iv-order-4',
    'iv-order-2',
    'iv-order-3',
  ];
  return (
    <section
      className={ivclass('xl:iv-pt-200 iv-pt-150 sm:iv-pt-100', sectionClass)}
    >
      <div
        className={ivclass(
          'xl:iv-mb-40 lg:iv-mb-20 md:iv-mb-25',
          'iv-text-grey-50',
          'iv-text-center',
          'iv-text-body-xl sm:iv-text-body-xs',
          'iv-m-body-xl sm:iv-m-body-xs',
          'iv-font-semibold',
        )}
      >
        {t(`common:brands.TrustedBy`)}
      </div>
      <div
        className={ivclass(
          'iv-grid iv-grid-cols-12',
          'sm:iv-grid-cols-3 sm:iv-gap-25',
        )}
      >
        <div
          className={ivclass(
            'xl:iv-col-span-10 iv-col-span-12 xl:iv-col-start-2',
          )}
        >
          <div
            className={ivclass(
              'lg:iv-px-[83px] md:iv-px-[45px] sm:iv-px-10',
              'iv-pt-7 md:iv-pt-6 sm:iv-pt-[33px]',
            )}
          >
            <div
              className={ivclass(
                'iv-flex iv-justify-between iv-items-center sm:iv-flex-wrap',
              )}
            >
              {sectionData.images.map((item, index) => (
                <div
                  key={item.Url}
                  className={ivclass(
                    'sm:iv-flex sm:iv-justify-center',
                    'sm:iv-w-1/3',
                    'sm:iv-max-h-[36px]',
                    'xl:iv-mx-20 lg:iv-mx-20 md:iv-mx-20 sm:iv-mb-[27px]',
                    'md:iv-px-9 sm:iv-py-1',
                    isMobile && logosOrder[index],
                  )}
                >
                  <ImageComponent
                    elementype="native"
                    src={item.Url}
                    alt={item.alt}
                    width={item.Width}
                    height={item.Height}
                    // loader={CustomImageLoader}
                    loading="lazy"
                    className="iv-object-cover sm:iv-object-contain"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedBy;
