import IVButton from '@invideoio/web-shared/components/Button';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { getWebpURL } from '@invideoio/web-shared/utils/common';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React, { useContext } from 'react';
import { BrowserType } from '@invideoio/web-shared/constants/common';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';
import { AUTH_TYPE } from '../../constants/homepage';
import { RedirectContext, StateContext } from '../../store/store';
import { SimpleHeadingType, CreationCardType } from '../../types';
import NumberFeatureCard from './NumberFeatureCard';
import { BaseAppType } from '@constants/common';

interface CreationSectionTemplatesProps {
  platform: PlatformType;
  heading: SimpleHeadingType;
  CTAUrl: string;
  buttonText: string;
  makeVideoTabletCard1: CreationCardType;
  makeVideoTabletCard2: CreationCardType;
  makeVideoTabletCard3: CreationCardType;
  makeVideoMobileCard1: CreationCardType;
  makeVideoMobileCard2: CreationCardType;
  makeVideoMobileCard3: CreationCardType;
  makeVideoDesktopCard1: CreationCardType;
  makeVideoDesktopCard2: CreationCardType;
  makeVideoDesktopCard3: CreationCardType;
  browser: BrowserType;
}

interface MakeVideoCardType {
  card1: CreationCardType;
  card2: CreationCardType;
  card3: CreationCardType;
}

const filterCard = (
  platform: PlatformType,
  props: CreationSectionTemplatesProps,
): MakeVideoCardType => {
  if (platform === PlatformType.Tablet) {
    return {
      card1: props.makeVideoTabletCard1,
      card2: props.makeVideoTabletCard2,
      card3: props.makeVideoTabletCard3,
    };
  }

  if (platform === PlatformType.Mobile) {
    return {
      card1: props.makeVideoMobileCard1,
      card2: props.makeVideoMobileCard2,
      card3: props.makeVideoMobileCard3,
    };
  }

  return {
    card1: props.makeVideoDesktopCard1,
    card2: props.makeVideoDesktopCard2,
    card3: props.makeVideoDesktopCard3,
  };
};

const CreationSectionTemplates = (props: CreationSectionTemplatesProps) => {
  const { heading, CTAUrl, buttonText, platform, browser } = props;
  const { card1, card2, card3 } = filterCard(platform, props);
  const { handleClick } = useContext(RedirectContext);
  const { baseAppType } = useContext(StateContext);
  const onClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'Creation Section',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.CreationSelection,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    });
    handleClick(CTAUrl, { tab: AUTH_TYPE.SIGNUP });
  };

  if (card3?.image?.url && browser !== BrowserType.Safari) {
    card3.image.url = getWebpURL(card3.image.url, platform);
  }

  return (
    <section
      className={ivclass(
        'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
        'xl:iv-pt-200 lg:iv-pt-150 md:iv-pt-150 sm:iv-pt-75',
      )}
    >
      <div
        className={ivclass(
          'xl:iv-pb-75 iv-pb-50 sm:iv-pb-30',
          'xl:iv-pl-40 iv-pl-35 sm:iv-pl-[25px]',
        )}
      >
        <h2
          className={ivclass(
            'iv-text-grey-100',
            'xl:iv-text-heading-4xl iv-text-heading-2xl sm:iv-text-heading-2xs',
            'xl:iv-m-heading-4xl iv-m-heading-2xl sm:iv-m-heading-2xs',
            'iv-font-extrabold',
          )}
        >
          {heading.plainHeading}
          <span className={ivclass('iv-title-gradient', 'sm:iv-block')}>
            {heading.highlightedHeading}
          </span>
        </h2>
      </div>
      <div
        className={ivclass(
          'iv-grid',
          'iv-grid-cols-12 md:iv-grid-cols-8',
          'iv-gap-40 md:iv-gap-25 sm:iv-gap-20',
        )}
      >
        <div
          className={ivclass(
            'iv-col-span-6 md:iv-col-span-12 sm:iv-col-span-12',
            'sm:iv-order-1',
          )}
        >
          <NumberFeatureCard
            details={card1.detail}
            heading={card1.heading}
            number={1}
            image={card1.image}
            containerClass={ivclass(
              'xl:iv-h-[600px] lg:iv-h-[620px] md:iv-h-[560px] sm:iv-h-[380px]',
              'iv-bg-black-4',
            )}
            titleColor="iv-text-black-85"
            highlightedTextClass="iv-text-black-85"
            numberClass={ivclass(
              'xl:iv-text-heading-10xl lg:iv-text-heading-10xl md:iv-text-heading-8xl sm:iv-text-heading-5xl',
              'iv-text-black-7',
            )}
            detailsWrapperClass={ivclass(
              'iv-pr-[32px]',
              'iv-pb-20 sm:iv-pb-10',
            )}
            headingClass={ivclass(
              'iv-pr-20',
              'xl:iv-text-heading-s iv-text-heading-2xs sm:iv-text-heading-3xs',
            )}
            wrapperClass={ivclass('iv-flex', 'iv-flex-col iv-justify-between')}
            detailsClass={ivclass(
              'xl:iv-text-heading-3xs lg:iv-text-heading-3xs md:iv-text-body-3xl sm:iv-text-body-xl',
              'xl:iv-pt-15 lg:iv-pt-15 md:iv-pt-[11px] sm:iv-pt-8',
              'iv-text-black-50',
            )}
            contentContainerClass={ivclass(
              'xl:iv-pl-[12px] xl:iv-pt-6 lg:iv-pl-[12px] lg:iv-pt-6 md:iv-pl-8 md:iv-pt-5 sm:iv-pl-4 sm:iv-pt-6',
            )}
            browser={browser}
            platform={platform}
          />
        </div>

        <div
          className={ivclass(
            'iv-col-span-6 md:iv-col-span-12 sm:iv-col-span-12',
            'sm:iv-order-2',
          )}
        >
          <NumberFeatureCard
            details={card2.detail}
            heading={card2.heading}
            number={2}
            image={card2.image}
            containerClass={ivclass(
              'xl:iv-h-[600px] lg:iv-h-[620px] md:iv-h-[560px] sm:iv-h-[380px]',
              'iv-creation-section-gradient',
            )}
            titleColor="iv-text-grey-0"
            highlightedTextClass="iv-text-grey-0"
            numberClass={ivclass(
              'xl:iv-text-heading-10xl lg:iv-text-heading-10xl md:iv-text-heading-8xl sm:iv-text-heading-5xl',
              'iv-text-white-25',
            )}
            detailsWrapperClass={ivclass(
              'iv-pr-[32px]',
              'iv-pb-20 sm:iv-pb-10',
            )}
            headingClass={ivclass(
              'iv-pr-20',
              'xl:iv-text-heading-s iv-text-heading-2xs sm:iv-text-heading-3xs',
            )}
            wrapperClass={ivclass('iv-flex', 'iv-flex-col iv-justify-between')}
            detailsClass={ivclass(
              'xl:iv-text-heading-3xs lg:iv-text-heading-3xs md:iv-text-body-3xl sm:iv-text-body-xl',
              'xl:iv-pt-15 lg:iv-pt-15 md:iv-pt-[11px] sm:iv-pt-8',
              'iv-text-white-50',
            )}
            contentContainerClass={ivclass(
              'xl:iv-pl-[12px] xl:iv-pt-6 lg:iv-pl-[12px] lg:iv-pt-6 md:iv-pl-8 md:iv-pt-5 sm:iv-pl-4 sm:iv-pt-6',
            )}
            browser={browser}
            platform={platform}
          />
        </div>

        <div className={ivclass('iv-col-span-12', 'sm:iv-order-3')}>
          <NumberFeatureCard
            details={card3.detail}
            heading={card3.heading}
            number={3}
            containerClass={ivclass(
              'xl:iv-h-[600px] lg:iv-h-[620px] md:iv-h-[560px] sm:iv-h-[443px]',
              'iv-bg-no-repeat iv-bg-cover iv-bg-center',
            )}
            titleColor="iv-text-black-85"
            highlightedTextClass="iv-text-black-85"
            numberClass={ivclass(
              'iv-pb-20 sm:iv-pb-10',
              'xl:iv-text-heading-10xl lg:iv-text-heading-10xl md:iv-text-heading-8xl sm:iv-text-heading-5xl',
              'iv-text-white-10',
            )}
            detailsWrapperClass={ivclass(
              'iv-pr-[32px]',
              'iv-pb-20 sm:iv-pb-10',
            )}
            detailsClass={ivclass(
              'xl:iv-text-heading-3xs lg:iv-text-heading-3xs md:iv-text-body-3xl sm:iv-text-body-xl',
              'xl:iv-pt-15 lg:iv-pt-15 md:iv-pt-[11px] sm:iv-pt-8',
              'iv-text-black-50',
            )}
            headingClass={ivclass(
              'iv-pr-20',
              'xl:iv-text-heading-s iv-text-heading-2xs sm:iv-text-heading-3xs',
            )}
            style={{
              backgroundImage: `url(${card3.image.url})`,
            }}
            contentContainerClass={ivclass(
              'xl:iv-pl-[12px] xl:iv-pt-6 lg:iv-pl-[12px] lg:iv-pt-6 md:iv-pl-8 md:iv-pt-5 sm:iv-pl-4 sm:iv-pt-6',
              'xl:iv-w-1/2 lg:iv-w-1/2 md:iv-w-full sm:iv-w-full',
            )}
            browser={browser}
            platform={platform}
          />
        </div>
      </div>
      <div className={ivclass('iv-pt-60 sm:iv-pt-30')}>
        <IVButton
          className={ivclass(
            'iv-flex',
            'iv-justify-center',
            'iv-items-center',
            'iv-cursor-pointer',
            'iv-h-65 sm:iv-h-28',
            'iv-border-2 iv-border-blue iv-border-solid',
            'iv-rounded-full',
            'iv-bg-grey-0 hover:iv-bg-blue iv-transition',
            'iv-px-40 sm:iv-px-15',
            'iv-mx-auto',
            'iv-text-blue hover:iv-text-grey-0',
            'iv-text-body-2xl sm:iv-text-body-m',
            'iv-font-bold',
          )}
          onClick={onClick}
        >
          {buttonText}
        </IVButton>
      </div>
    </section>
  );
};

export default CreationSectionTemplates;
