import ErrorBoundary from '@components/ErrorBoundary';
import CreationSectionNonTemplates from '@components/creation-section/CreationSectionNonTemplates';
import HeaderWithCTAAI from '@components/header-ai';
import HeaderWithCTA from '@components/header-with-cta';
import HeroSection from '@components/hero-section';
import PricingPlan from '@components/pricing-plan';
import SuperbVideoCTA from '@components/superb-videos-cta';
import TrustedBy from '@components/trusted-by';
import CustomTrustedBy from '@components/trusted-by/customTrustedBy';
import { BaseAppType } from '@constants/common';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import FAQ from '@invideoio/web-shared/components/Faq';
import Footer from '@invideoio/web-shared/components/Footer';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import FooterConfig from '@invideoio/web-shared/constants/footer';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';
import { Faq } from '../../constants/makepage';
import AppLayout from '../../layouts/AppLayout';
import { StateContext } from '../../store/store';
import { MakePageResponseType } from '../../types';
import dynamic from 'next/dynamic';

const Templates = dynamic(
  () =>
    import(
      /* webpackChunkName: "Templates" */
      '@components/templates'
    ),
);

const FixedBanner = dynamic(
  () =>
    import(
      /* webpackChunkName: "FixedBanner" */
      '@invideoio/web-shared/components/FixedBanner'
    ),
);

const GuideSection = dynamic(
  () =>
    import(
      /* webpackChunkName: "GuideSection" */
      '@components/guide-section'
    ),
);

const Achievements = dynamic(
  () =>
    import(
      /* webpackChunkName: "Achievements" */
      '@components/achievements'
    ),
);

const FeaturesCard = dynamic(
  () =>
    import(
      /* webpackChunkName: "FeaturesCard" */
      '@components/features-card'
    ),
);

interface NonTemplateProps {
  makePageState: MakePageResponseType;
}

const NonTemplateSection = (props: NonTemplateProps) => {
  const globalState = useContext(StateContext);
  const { makePageState } = props;
  const { platform, os, browser } = globalState;
  const { t } = useTranslation();

  const { feature, featureCard3Desktop, featureCard3Mobile, featureCard7 } =
    makePageState;
  const showFeatureCard =
    feature?.card1 &&
    feature?.card2 &&
    feature?.sectionHead &&
    featureCard3Desktop &&
    featureCard3Mobile &&
    featureCard7;

  const {
    creationSection,
    creationDesktopCard1,
    creationDesktopCard2,
    creationDesktopCard3,
    creationTabletCard1,
    creationTabletCard2,
    creationTabletCard3,
    creationMobileCard1,
    creationMobileCard2,
    creationMobileCard3,
  } = makePageState;
  const showCreationSection =
    creationSection?.CTAUrl &&
    creationSection?.buttonText &&
    creationSection?.heading &&
    creationDesktopCard1 &&
    creationDesktopCard2 &&
    creationDesktopCard3 &&
    creationTabletCard1 &&
    creationTabletCard2 &&
    creationTabletCard3 &&
    creationMobileCard1 &&
    creationMobileCard2 &&
    creationMobileCard3;

  const { guideCards, guideHeading } = makePageState;
  const showGuideSection = guideCards && guideHeading;

  const {
    templateSection,
    templateSlide_1,
    templateSlide_2,
    templateSlide_3,
    templateSlide_4,
  } = makePageState;
  const showTemplateSection =
    templateSection?.heading &&
    templateSection?.buttonText &&
    templateSection?.CTAUrl &&
    templateSlide_1 &&
    templateSlide_2 &&
    templateSlide_3 &&
    templateSlide_4;

  const { BaseAppType: baseAppType = BaseAppType.STUDIO } = makePageState;

  return (
    <AppLayout platform={platform}>
      {baseAppType === BaseAppType.AI ? (
        <>
          <HeaderWithCTAAI />
        </>
      ) : (
        <>
          <FixedBanner browser={browser} />
          <HeaderWithCTA />
        </>
      )}
      <div
        className={ivclass(
          'iv-max-w-[100vw] iv-overflow-hidden iv-pb-60 iv-mb-[-100px]',
        )}
      >
        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <ErrorBoundary>
            <HeroSection
              browser={browser}
              heroSection={makePageState.heroSection}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
        <Achievements />
        {showFeatureCard && (
          <ErrorBoundary>
            <FeaturesCard
              platform={platform as PlatformType}
              browser={browser}
              sectionHead={feature.sectionHead}
              card1={feature.card1}
              card2={feature.card2}
              card3Desktop={featureCard3Desktop}
              card3Mobile={featureCard3Mobile}
              card7={featureCard7}
            />
          </ErrorBoundary>
        )}
        {(!!makePageState?.trustedBy && makePageState?.trustedBy.length > 0 && (
          <ErrorBoundary>
            <CustomTrustedBy
              trustedBy={makePageState.trustedBy}
              sectionHead={t(`TrustedBySection.sectionHead`)}
            />
          </ErrorBoundary>
        )) || <TrustedBy isMobile={platform === 'mobile'} />}
        {showCreationSection && (
          <ErrorBoundary>
            <CreationSectionNonTemplates
              platform={platform}
              browser={browser}
              heading={creationSection.heading}
              CTAUrl={creationSection.CTAUrl}
              buttonText={creationSection.buttonText}
              makeVideoTabletCard1={creationTabletCard1}
              makeVideoTabletCard2={creationTabletCard2}
              makeVideoTabletCard3={creationTabletCard3}
              makeVideoMobileCard1={creationMobileCard1}
              makeVideoMobileCard2={creationMobileCard2}
              makeVideoMobileCard3={creationMobileCard3}
              makeVideoDesktopCard1={creationDesktopCard1}
              makeVideoDesktopCard2={creationDesktopCard2}
              makeVideoDesktopCard3={creationDesktopCard3}
            />
          </ErrorBoundary>
        )}
      </div>
      <div
        className={ivclass(
          'iv-bg-grey-100',
          'xl:iv-mt-200 iv-mt-150 sm:iv-mt-100',
        )}
      >
        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <ErrorBoundary>
            <PricingPlan platform={platform as PlatformType} />
          </ErrorBoundary>
        </div>
      </div>
      {showGuideSection && (
        <ErrorBoundary>
          <GuideSection
            platform={platform}
            cards={guideCards}
            heading={guideHeading}
          />
        </ErrorBoundary>
      )}
      {showTemplateSection && (
        <ErrorBoundary>
          <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
            <Templates
              platform={platform}
              slide_1={templateSlide_1}
              slide_2={templateSlide_2}
              slide_3={templateSlide_3}
              slide_4={templateSlide_4}
              heading={templateSection.heading}
              CTAUrl={templateSection.CTAUrl}
              buttonText={templateSection.buttonText}
            />
          </div>
        </ErrorBoundary>
      )}
      {makePageState.FaqItems && makePageState.FAQSection && (
        <ErrorBoundary>
          <div className={ivclass('iv-pt-50 md:iv-pt-20 sm:iv-pt-40')}>
            <FAQ
              platform={PlatformType.Desktop}
              title={{
                ...makePageState.FAQSection.title,
                highlightedHeadingClass:
                  'iv-block iv-title-gradient iv-leading-tight',
              }}
              items={makePageState.FaqItems}
              icons={Faq.icons}
            />
          </div>
        </ErrorBoundary>
      )}
      {makePageState.superbVideos && (
        <ErrorBoundary>
          <SuperbVideoCTA
            heading={makePageState.superbVideos.heading}
            detail={makePageState.superbVideos.detail}
            buttonText={makePageState.superbVideos.buttonText}
            CTAUrl={makePageState.superbVideos.CTAUrl}
          />
        </ErrorBoundary>
      )}
      <Footer
        footer={
          baseAppType === BaseAppType.AI
            ? FooterConfig.FOOTER_AI
            : FooterConfig.FOOTER
        }
        os={os}
        platform={platform}
        socialLinks={globalState.socialLinks}
      />
    </AppLayout>
  );
};

export default NonTemplateSection;
