import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import PageHeading from '@components/PageHeading';
import { HeroSectionTitleType } from '../../types';
import { HeadingStyleType } from '../../constants/common';

interface TitleProps {
  heading: HeroSectionTitleType;
}

const Title = (props: TitleProps) => {
  const {
    heading1,
    heading2,
    heading3,
    heading4,
    heading1Style,
    heading2Style,
    heading3Style,
    heading4Style,
  } = props.heading;
  return (
    <PageHeading
      className={ivclass(
        'iv-text-heading-5xl lg:iv-text-heading-4xl md:iv-text-heading-4xl sm:iv-text-heading-s',
        'iv-m-heading-5xl lg:iv-m-heading-4xl md:iv-m-heading-4xl sm:iv-m-heading-m',
        'md:iv-text-center sm:iv-text-center',
        'iv-text-grey-100',
        'iv-font-extrabold',
      )}
    >
      <span className={ivclass('iv-block')}>
        <span
          className={ivclass(
            heading1Style === HeadingStyleType.Highlighted &&
              'iv-title-gradient',
            'iv-decoration-clone',
          )}
        >
          {heading1}
        </span>
        <span
          className={ivclass(
            heading2Style === HeadingStyleType.Highlighted &&
              'iv-title-gradient',
            'iv-decoration-clone',
          )}
        >
          {heading2}
        </span>
      </span>
      <span className={ivclass('iv-block')}>
        <span
          className={ivclass(
            heading3Style === HeadingStyleType.Highlighted &&
              'iv-title-gradient',
            'iv-decoration-clone',
          )}
        >
          {heading3}
        </span>
        <span
          className={ivclass(
            heading4Style === HeadingStyleType.Highlighted &&
              'iv-title-gradient',
            'iv-decoration-clone',
          )}
        >
          {heading4}
        </span>
      </span>
    </PageHeading>
  );
};

export default Title;
